import { Controller } from "@hotwired/stimulus"
import Popover from 'bootstrap/js/dist/popover';

// Connects to data-controller="popover"
export default class extends Controller {
  connect() {
    this.setPop()
  }

  setPop(){
    return new Popover(this.element)
  }
}
