import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="fiscal-receivers"
export default class extends Controller {
  connect() {
  }

  upcase(event){
    event.target.value = event.target.value.toUpperCase();
  }
}
