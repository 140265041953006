import { Controller } from "@hotwired/stimulus"
import Tagify from '@yaireo/tagify'

// Connects to data-controller="tags"
export default class extends Controller {
  connect() {
    this.#setTagInput()
  }

  #setTagInput(){
    new Tagify(this.element, {
      whitelist : this.element.dataset["whitelist"] ? this.element.dataset["whitelist"] : [],
      dropdown : {
          classname     : "color-blue",
          enabled       : 0,
          maxItems      : 5,
          position      : "text",
          closeOnSelect : false,
          highlightFirst: true
      }
    });
  }
}
