import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="products-services"
export default class extends Controller {
  connect() {
  }
  static targets = ["physicPersonTaxInfoContainer", "moralPersonTaxInfoContainer", "moralObjectOfTaxesForm", "physicObjectOfTaxesForm",
                    "productServiceId", "productServiceId", "physicPersonIvaTaxFreeForm", "moralPersonIvaTaxFreeForm"]

  getPhysicObjectOfTaxesForm(event){
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}/physic_object_of_taxes`
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" }
    })
    .then(response => response.text())
    .then((data) => {
      this.#animateAndSetContent(this.physicPersonTaxInfoContainerTarget, data);
      })
  }

  getMoralObjectOfTaxesForm(event){
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}/moral_object_of_taxes`
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" }
    })
    .then(response => response.text())
    .then((data) => {
      this.#animateAndSetContent(this.moralPersonTaxInfoContainerTarget, data);
      })
  }

  updatePhysicPersonIvaTaxFree(event){
    console.log("Heyyy")
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}`
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.physicPersonIvaTaxFreeFormTarget)
    })
    .then(response => response.text())
    .then((data) => {

      })
  }

  updateMoralPersonIvaTaxFree(event){
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}`
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.moralPersonIvaTaxFreeFormTarget)
    })
    .then(response => response.text())
    .then((data) => {

      })
  }

  updateMoralObjectOfTaxes(event){
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}/moral_object_of_taxes`
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.moralObjectOfTaxesFormTarget)
    })
    .then(response => response.text())
    .then((data) => {
      this.#animateAndSetContent(this.moralPersonTaxInfoContainerTarget, data);
      })
  }

  updatePhysicObjectOfTaxes(event){
    event.preventDefault()
    const productServiceId = this.productServiceIdTarget.value
    const url = `/api/v1/internal_resources/product_services/${productServiceId}/physic_object_of_taxes`
    fetch(url, {
      method: "PATCH",
      headers: { "Accept": "text/plain" },
      body: new FormData(this.physicObjectOfTaxesFormTarget)
    })
    .then(response => response.text())
    .then((data) => {
      this.#animateAndSetContent(this.physicPersonTaxInfoContainerTarget, data);
      })
  }

  #animateAndSetContent(target, content) {
    target.style.opacity = 0;
    target.innerHTML = content;
    target.animate([{ transform: 'translateX(-20px)', opacity: '0' }, { transform: 'translateX(0)', opacity: '1' }], {
      duration: 500,
      easing: 'ease-out',
      fill: 'both'
    });
  }
}
