import { Controller } from "@hotwired/stimulus"


// Connects to data-controller="sales"
export default class extends Controller {
  connect() {
    console.log("JEUY")
  }

  static targets = ["saleForm", "subtotal", "total", "concept", "price", "conceptTotal", "conceptSubtotal", "quantity", "conceptLabel", "productService", "proofOfPaymentForm",
                    "item", "content"]

  calculateConceptTotals(event) {
    const elems = this.#getConceptFields(event)
    const url = `/concept/simulate_totals?product_service_id=${elems["productService"].value}&price=${elems["price"].value}&quantity=${elems["quantity"].value}`
    fetch(url, {
      method: "GET",
      headers: { "Accept": "application/json" },
    })
    .then(response => response.json())
    .then((data) => {
      elems["conceptLabel"].innerHTML = data["description"]
      elems["conceptSubtotal"].innerHTML = this.#formatNumber(parseFloat(data["subtotal"]))
      elems["conceptSubtotal"].dataset["subtotal"] = data["subtotal"]
      elems["conceptTotal"].innerHTML = this.#formatNumber(parseFloat(data["total"]))
      elems["conceptTotal"].dataset["total"] = data["total"]
      this.#setSaleTotals()
      })
  }


  // The following method is used in customers/sale/:id/proof_of_payment
  updateProofOfPayment(event){
    this.proofOfPaymentFormTarget.submit()
  }


  toggleConceptsAccordion(event){
    this.itemTargets.forEach(item => {
      if (item === event.currentTarget || item == event.currentTarget.parentElement) {
          item.classList.toggle("active");
          item.querySelector(".accordion-container").style.maxHeight = item.classList.contains("active") ? 'fit-content' : '0';
      } else {
          item.classList.remove("active");
          item.querySelector(".accordion-container").style.maxHeight = '0';
      }
    });
  }

  #formatNumber(number){
    const options = {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };
    return number.toLocaleString(number, options);
  }

  #getConceptFields(event){
    let priceElm, quantityElem, productServiceElem, conceptLabelElem, conceptSubtotalElem, conceptTotalElem;
    const currentItem = event.target.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement
    this.priceTargets.forEach((price) =>{
      if(price.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement == currentItem) priceElm = price
    })
    this.quantityTargets.forEach((quantity) => {
      if(quantity.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement == currentItem) quantityElem = quantity
    })
    this.productServiceTargets.forEach((productService) => {
      if(productService.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement == currentItem) productServiceElem = productService
    })
    this.conceptLabelTargets.forEach((conceptLabel) => {
      if(conceptLabel.parentElement.parentElement.parentElement == currentItem) conceptLabelElem = conceptLabel
    })
    this.conceptTotalTargets.forEach((conceptTotal) => {
      if(conceptTotal.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement == currentItem) conceptTotalElem = conceptTotal
    })
    this.conceptSubtotalTargets.forEach((conceptSubtotal) => {
      if(conceptSubtotal.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement == currentItem) conceptSubtotalElem = conceptSubtotal
    })
    const elems = {
      "price": priceElm,
      "productService": productServiceElem,
      "quantity": quantityElem,
      "conceptLabel": conceptLabelElem,
      "conceptSubtotal": conceptSubtotalElem,
      "conceptTotal": conceptTotalElem
    }
    return elems
  }

  #setSaleTotals(){
    let subtotal = 0
    let total = 0
    this.conceptTotalTargets.forEach((conceptTotal) => {
      total += parseFloat(conceptTotal.dataset["total"])
    })
    this.conceptSubtotalTargets.forEach((conceptSubtotal) => {
      subtotal += parseFloat(conceptSubtotal.dataset["subtotal"])
    })
    this.totalTarget.innerHTML = this.#formatNumber(total)
    this.subtotalTarget.innerHTML = this.#formatNumber(subtotal)
  }
}
