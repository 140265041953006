import { Controller } from "@hotwired/stimulus"
import Litepicker from 'litepicker';
import 'litepicker/dist/plugins/ranges';


// Connects to data-controller="members-dashboard"
export default class extends Controller {
  connect() {
    const elem1= this.element.querySelector('#litepicker1')
    const picker1 = new Litepicker({
      element: elem1,
      singleMode: false,
      plugins: ['ranges'],
      numberOfMonths: 2,
      numberOfColumns: 2,
      lang: "es",
      ranges: {
        customRanges: {
          'Hoy': [new Date(), new Date()],
          'Ayer': this.#getYesterdayDateArray(),
          'Última semana': this.#getLastWeekDateArray(),
          'Último mes': this.#getLastMonthDateArray(),
          'Este mes': this.#getThisMonthDateArray(),
          'Mes anterior': this.#getLastMonthDataArray()
        }
      }
    });

    const elem2= this.element.querySelector('#litepicker2')
    const picker2 = new Litepicker({
      element: elem2,
      singleMode: false,
      plugins: ['ranges'],
      numberOfMonths: 2,
      numberOfColumns: 2,
      lang: "es",
      ranges: {
        customRanges: {
          'Hoy': [new Date(), new Date()],
          'Ayer': this.#getYesterdayDateArray(),
          'Última semana': this.#getLastWeekDateArray(),
          'Último mes': this.#getLastMonthDateArray(),
          'Este mes': this.#getThisMonthDateArray(),
          'Mes anterior': this.#getLastMonthDataArray()
        }
      }
    });
  }

  #getYesterdayDateArray(){
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return [yesterday, yesterday]
  }

  #getLastWeekDateArray(){
    const dates = [];
    const today = new Date();
    const oneWeekAgo = new Date(today);
    oneWeekAgo.setDate(today.getDate() - 7);
    dates.push(oneWeekAgo, today);
    return dates;
  }

  #getLastMonthDateArray(){
    const dates = [];
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);
    dates.push(oneMonthAgo, today);
    return dates;
  }

  #getThisMonthDateArray(){
    const dates = [];
    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    dates.push(firstDayOfMonth, today);

    return dates;
  }

  #getLastMonthDataArray(){
    const dates = [];
    const today = new Date();
    const firstDayOfLastMonth = new Date(today.getFullYear(), today.getMonth() - 1, 1);
    const lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

    dates.push(firstDayOfLastMonth, lastDayOfLastMonth);

    return dates;
  }
}
