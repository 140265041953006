import { Controller } from "@hotwired/stimulus"
import Rails from 'rails-ujs'
import lottie from 'lottie-web'
import confetti from 'canvas-confetti';

// Connects to data-controller="customer-portal"
export default class extends Controller {
  connect() {
    const animationPath = "/lottie/loader.json";
    const options = {
        container: this.loaderTarget,
        renderer: "svg",
        loop: true,
        autoplay: true,
        path: animationPath,
    };
    lottie.loadAnimation(options);
  }

  static targets = ["form", "content", "loader", "loaderContainer", "entireContentContainer"]

  createFiscalReceiver(event) {
    event.preventDefault();
    if(this.formTarget.dataset["triggerLoader"] === "true" && event.target.dataset["manualUpload"] !== "true") this.#loader("show");
    const url = `/customer_portal/invoice_requests/${this.formTarget.dataset["invoiceRequestId"]}/customer/fiscal_receivers?token=${this.#getToken()}&manual_upload=${event.target.dataset["manualUpload"]}&foregin=${event.target.dataset["foregin"]}`;
    fetch(url, {
      method: "POST",
      body: event.target.dataset["manualUpload"] === "true" ? {"field": "fsc"} : new FormData(this.formTarget) ,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      this.#updateContent(data)
    })
  }

  updateFiscalReceiver(event){
    event.preventDefault();
    if(this.formTarget.dataset["triggerLoader"] === "true") this.#loader("show");
    const url = `/customer_portal/invoice_requests/${this.formTarget.dataset["invoiceRequestId"]}/customer/fiscal_receivers/${this.formTarget.dataset["fiscalReceiverId"]}?token=${this.#getToken()}&actual_form=${this.formTarget.dataset["actualForm"]}&next_form=${this.formTarget.dataset["nextForm"]}`;
    fetch(url, {
      method: "PATCH",
      body: new FormData(this.formTarget) ,
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      this.#updateContent(data)
    })
  }

  updateCustomer(event){
    event.preventDefault();
    const url = `/customer_portal/invoice_requests/${this.formTarget["dataset"]["invoiceRequestId"]}/customer?token=${this.#getToken()}&actual_form=${this.formTarget.dataset["actualForm"]}&next_form=${this.formTarget.dataset["nextForm"]}`;
    fetch(url, {
      method: "PATCH",
      body: new FormData(this.formTarget),
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      this.#updateContent(data)
    })
  }

  updateInvoiceRequest(event){
    event.preventDefault();
    if(this.formTarget.dataset["triggerLoader"] === "true") this.#loader("show");
    const url = `/customer_portal/invoice_requests/${this.formTarget["dataset"]["invoiceRequestId"]}?token=${this.#getToken()}&actual_form=${this.formTarget.dataset["actualForm"]}&next_form=${this.formTarget.dataset["nextForm"]}`;
    fetch(url, {
      method: "PATCH",
      body: new FormData(this.formTarget),
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if(data["request_finished"])
        this.#successRequest(data)
      else
        this.#updateContent(data)
    })
  }

  createInvoice(event){
    event.preventDefault();
    this.#loader("show")
    const invoiceRequestId = event.target.dataset["invoiceRequestId"] || event.target.parentNode.dataset["invoiceRequestId"]
    const url = `/customer_portal/invoice_requests/${invoiceRequestId}/invoice?token=${this.#getToken()}`;
    fetch(url, {
      method: "POST",
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': Rails.csrfToken()
      }
    })
    .then(response => {
      return response.json();
    })
    .then(data => {
      if(data["success"])
        this.#successRequest(data)
      else
        this.#updateContent(data)
    })
  }

  getForm(event){
    event.preventDefault()
    const url = event.target.dataset["url"] || event.target.parentNode.dataset["url"]
    fetch(url, {
      method: "GET",
      headers: { "Accept": "text/plain" }
    })
    .then(response => response.json())
    .then((data) => {
      const direction = event.target.dataset["direction"] === "right" ? "right" : "left"
      this.#updateContent(data, direction)
      })

  }

  upcase(event){
    event.target.value = event.target.value.toUpperCase()
  }


  #updateUrl(url){
    window.history.pushState({}, '', url);
  }

  #animateAndSetContent(content, direction, success = false) {
    let fromTranslation, toTranslation;

    if (direction === 'right') {
      fromTranslation = '0';
      toTranslation = '-100%';
    } else if (direction === 'left') {
      fromTranslation = '0';
      toTranslation = '100%';
    } else {
      return;
    }

    let container = success ? this.entireContentContainerTarget : this.contentTarget

    container.animate(
      [
        { transform: `translateX(${fromTranslation})`, opacity: '1' },
        { transform: `translateX(${toTranslation})`, opacity: '0' }
      ], {
        duration: 300,
        easing: 'ease-out',
        fill: 'both'
      }
    ).onfinish = () => {
      container.style.opacity = 0;
      container.style.transform = `translateX(${direction === 'right' ? '100%' : '-100%'})`;
      container.innerHTML = content;
      container.animate(
        [
          { transform: `translateX(${direction === 'right' ? '100%' : '-100%'})`, opacity: '0' },
          { transform: 'translateX(0)', opacity: '1' }
        ], {
          duration: 300,
          easing: 'ease-out',
          fill: 'both'
        }
      );
    };
  }

  #getToken(){
    const actualUrl = window.location.href;
    const url = new URL(actualUrl)
    const params = new URLSearchParams(url.search)
    return params.get("token")
  }

  #loader(action){
    if(action === "show"){
      this.loaderContainerTarget.style.display = "block";
    }else{
      this.loaderContainerTarget.style.display = "none";
    }
  }

  #updateContent(data, fadeDirection = "right"){
    if(data["status"] === 422 || data["error"] || data["success"] === false){
      this.contentTarget.innerHTML = data["text"]
    }
    else{
      this.#animateAndSetContent(data["text"], fadeDirection);
    }
    this.#loader("hide")
    if(data["path_on_window"]) this.#updateUrl(data["path_on_window"]);
  }

  #successRequest(data){
    console.dir(data)
    this.#animateAndSetContent(data["text"], "right", true);
    this.#updateUrl(data["path_on_window"])
    this.#triggerConfetti()
  }

  #triggerConfetti(){
    confetti({
      angle: 60,
      spread: 120,
      particleCount: 100,
      origin: { x: 0 },
      colors: ['#ff0000', '#00ff00', '#0000ff']
    });

    confetti({
      angle: 120,
      spread: 120,
      particleCount: 100,
      origin: { x: 1 },
      colors: ['#ff0000', '#00ff00', '#0000ff']
    });

  }
}
;
