import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.loadChatwoot();
    this.waitForChatwootIframe();
  }

  loadChatwoot() {
    if (window.chatwootSDK) return;

    window.chatwootSettings = {
      position: "right",
      type: "standard",
      launcherTitle: "Chatea con nosotros"
    };

    const BASE_URL = "https://app.chatwoot.com";
    const script = document.createElement("script");
    script.src = BASE_URL + "/packs/js/sdk.js";
    script.defer = true;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      window.chatwootSDK.run({
        websiteToken: 'recw2hrnNwqARSnTTczGkqBo',
        baseUrl: BASE_URL
      });
    };
  }

  waitForChatwootIframe() {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        mutation.addedNodes.forEach((node) => {
          if (node.nodeName === 'IFRAME' && node.dataset.chatwoot) {
            // Aquí, el IFrame de Chatwoot está disponible y puedes ejecutar el código relacionado
            observer.disconnect(); // Desconecta el observador una vez que el IFrame esté listo
          }
        });
      });
    });

    observer.observe(document.body, { childList: true, subtree: true });
  }
}
