import { Controller } from "@hotwired/stimulus"
import intlTelInput from 'intl-tel-input';

// Connects to data-controller="intl-tel-input"
export default class extends Controller {
  static targets = ["countryCode"]

  connect() {
    const input = this.element.querySelector("#phone");
    this.itl = intlTelInput(input, {
      utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@20.0.4/build/js/utils.js",
      initialCountry: "mx",
      preferredCountries: ['mx', 'us', 'ca']
    });

    input.addEventListener("countrychange", () => this.setCountryCode());
  }

  setCountryCode() {
    const countryData = this.itl.getSelectedCountryData();
    this.countryCodeTarget.value = countryData.dialCode;

  }
}
