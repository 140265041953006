import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="customers"
export default class extends Controller {
  connect() {
  }

  submitForm(event){
    event.preventDefault();
    this.element.requestSubmit();
  }
}
