import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="pdf-embedded"
export default class extends Controller {
  connect() {
    let pdfUrl = this.adobeContainerTarget.dataset.pdfUrl
    const fileName = this.adobeContainerTarget.dataset.fileName
    pdfUrl = pdfUrl.replace('http://','https://')
    document.addEventListener("adobe_dc_view_sdk.ready", function(){
      var adobeDCView = new AdobeDC.View({clientId: "f058f1e0ce394cd4a1cca6e7c0868da8", divId: "adobe-dc-view"});
      adobeDCView.previewFile({
        content:{location: {url: pdfUrl}},
        metaData:{fileName: fileName}
      }, {embedMode: "IN_LINE"});
    });

  }
  static targets = ["adobeContainer"]
}
